<template>
  <div class="px-4 py-1 bg-gray-100 rounded-md border-transparent">
    <ul class="flex mb-2 mt-1 items-center text-sm">
      <li>
        <router-link v-if="$store.state.user.lzv2.upload" to="/campanhas">
          <span class="text-gray-800 font-semibold inline-block mr-2">Home</span>
        </router-link>
        <router-link
          v-if="!$store.state.user.lzv2.upload && $store.state.user.lzv2.validacao"
          to="/temzap"
        >
          <span class="text-gray-800 font-semibold inline-block mr-2">Home</span>
        </router-link>
      </li>
      <li v-for="(link, index) in links" :key="index" class="active">
        <span class="inline-block mr-2 text-gray-600">></span>
        <router-link :to="link.url">
          <span
            class="inline-block mr-2"
            :class="links.length - 1 === index && 'text-gray-600'"
            >{{ link.value }}</span
          >
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    links: {
      type: Array,
      required: false,
      default: () => [{ url: "/", value: "No value Provided" }],
    },
  },
  data() {
    return {};
  },
};
</script>
