<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5">Relatório validação Whatsapp</h1>
    <form class="mb-2">
      <div class="grid grid-cols-12 gap-3">
        <div class="col-span-12 md:col-span-2">
          <label for="de" class="mt-2 block text-sm font-medium">A partir de</label>
          <input
            v-model="form.de"
            type="datetime-local"
            name="de"
            id="de"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-2">
          <label for="ate" class="mt-2 block text-sm font-medium">Até</label>
          <input
            v-model="form.ate"
            type="datetime-local"
            name="ate"
            id="ate"
            class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          />
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2" v-if="$store.state.user.admin">
          <label for="dataDisparo" class="mt-2 block text-sm font-medium text-gray-700">
            Centro de custo</label
          >
          <v-select v-model="centrocusto" :options="centroscusto" label="nome" :reduce="(c) => c._id" name="centrocusto" id="centrocusto" class="mt-1"></v-select>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <button
            @click="list"
            type="button"
            class="mt-8 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Gerar relatório</span>
          </button>
        </div>
        <div class="col-span-12 md:col-span-3 2xl:col-span-2">
          <a
            :href="`${api}/v1/campanhasLZV2/relatorio-envio/download?token=${$store.state.token}&de=${form.de}&ate=${form.ate}&validacao=${true}`"
            role="button"
            type="button"
            class="md:mt-8 bg-gray-500 hover:bg-gray-600 focus:bg-gray-700 focus:shadow-sm focus:ring-opacity-50 text-white py-2 rounded-md text-sm shadow-sm hover:shadow-md w-full font-semibold text-center"
          >
            <span class="inline-block mr-2">Download</span>
          </a>
        </div>
      </div>
    </form>
    <div class="grid md:grid-cols-3 gap-3 mt-3 mb-1">
      <label>Total: {{ totalEnvios }}</label>
      <label>Total com whatsapp: {{ totalEnviados }}</label>
      <label>Total sem whatsapp: {{ totalNaoEnviados }}</label>
    </div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Arquivo
                  </th>

                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Data
                  </th>

                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Total
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Tem zap
                  </th>
                  <th
                    scope="col"
                    class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Não tem zap
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="el in campanhas" :key="el._id">
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <span
                      v-if="
                        el.arquivoUpload &&
                        el.arquivoUpload.originalname.indexOf(
                          'arquivo-modelo-lembrazap'
                        ) >= 0
                      "
                      >{{ el.nome }}</span
                    >
                    <span
                      v-if="
                        el.arquivoUpload &&
                        el.arquivoUpload.originalname.indexOf(
                          'arquivo-modelo-lembrazap'
                        ) < 0
                      "
                      >{{ el.arquivoUpload.originalname }}</span
                    >
                    <small v-if="!el.arquivoUpload || !el.arquivoUpload.originalname">{{
                      el.nome
                    }}</small>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ moment(el.dataHoraDisparo).format("DD/MM/YYYY") }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ el.total }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ el.totalEnviado }}
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    {{ el.totalFalhas }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <pagination
            v-model="page"
            :per-page="perPage"
            :records="total"
            @paginate="setPage"
            class="pagination"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Breadcrumb from "../../components/Breadcrumbs.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      breadcrumb: [{ url: "/relatorios/relatorio-temzap", value: "Relatório Temzap" }],
      route: "relatorio-temzap",
      apilz: window.location.hostname === 'uno.trestto.com.br' ? 'https://uno.trestto.com.br/api' : process.env.VUE_APP_API,
      moment: moment,
      campanhas: [],
      centroscusto: [],
      centrocusto: null,
      page: 1,
      perPage: 25,
      form: {
        busca: "",
        de: moment().startOf("d").format("YYYY-MM-DDTHH:mm"),
        ate: moment().endOf("d").format("YYYY-MM-DDTHH:mm"),
      },
      total: 0,
      totalEnvios: 0,
      totalEnviados: 0,
      totalNaoEnviados: 0,
    };
  },
  methods: {
    async setPage(page) {
      this.page = page;

      const skip = (page - 1) * this.perPage;
      const limit = this.perPage;

      const listReq = await this.$http.post(`/v1/campanhasLZV2/relatorio-envio`, {
        de: this.form.de,
        ate: this.form.ate,
        centrocusto: this.centrocusto,
        validacao: true,
        limit,
        skip,
      });

      this.campanhas = listReq.data.data;
      this.total = listReq.data.total;
      this.totalEnvios = listReq.data.relatorio.total;
      this.totalEnviados = listReq.data.relatorio.totalEnviados;
      this.totalNaoEnviados = listReq.data.relatorio.totalNaoEnviados;
    },
    async list() {
      this.setPage(1);
    },
  },
  async beforeMount() {

    const centroscustoReq = await this.$http.post(`/v1/centrocusto/list`, { all: true });
    this.centroscusto = centroscustoReq.data.data;

    if (this.$store.state.user.centrocusto) {
      this.centrocusto = this.$store.state.user.centrocusto;
    }

    this.list();
  },
};
</script>
